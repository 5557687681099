import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Top.vue';

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, meta: { breadcrumb: 'Home' } },
  {
    path: '/companyAccess',
    name: 'CompanyAccess',
    meta: { 
      title:  {'ja':'アクセス' ,'en':'Access'},
      seoTitle:  'アクセス | 国産車・外車を問わず希少な中古車・プレミアムカーを販売 | 株式会社 MIX YARD',
      desc: '306-0504 茨城県坂東市生子 TEL:0280-23-6262 MAIL:info@mixyard.co.jp' },
    component: () => import(/* webpackChunkName: "companyAccess" */ '../components/pages/CompanyAccess.vue')
  },
  {
    path: '/companyInfo',
    name: 'CompanyInfo',
    meta: { 
      title:  {'ja':'会社情報' ,'en':'Company'},
      seoTitle: 'GT-RやAMGなど国産車・外車問わず希少な中古車・プレミアムカーを販売 | 株式会社 MIX YARD | 会社情報',
      desc: 'GT-RやAMGなどの中古車販売を行う株式会社MIX YARDは、物の大切さを理解し、心を込めてモノを扱うことをポリシーとしています。中古車でも長く安全に走れる車をお探しの方はぜひお問い合わせください。'},
    component: () => import(/* webpackChunkName: "companyInfo" */ '../components/pages/CompanyInfo.vue')
  },
  {
    path: '/column',
    name: 'Column',
    meta: { 
      breadcrumb: 'コラム一覧',
      title:  {'ja':'コラム' ,'en':'column'},
      seoTitle:'コラム一覧 | プレミアムカー・MT車の中古車販売「car shop MIX」 | car shop MIX',
      desc: 'プレミアムカーやGT-R・MT車などの中古車を販売する「car shop MIX」のお役立ちコラム一覧ページです。こちらでは、中古車購入時にチェックしておきたいことや国産車・外車の人気メーカーの魅力をご紹介いたします。ぜひご参考にしてください。| car shop MIX'
    },
    component: () => import(/* webpackChunkName: "column" */ '../components/pages/Column.vue'),
  },
  {
    path: '/column/aboutUsedCarGtR',
    name: 'AboutUsedCarGtR',
    meta: { 
      breadcrumb: '【中古車】GT-Rの歴史と「R34」「R35」の特徴',
      title:  {'ja':'コラム【中古車】GT-Rの歴史と「R34」「R35」の特徴' ,'en':'column'},
      seoTitle:'【中古車】GT-Rの歴史と「R34」「R35」の特徴 | car shop MIX',
      desc: '日産自動車が手がけるGT-Rは、日本が世界に誇るスポーツカーです。カーレースで数々の記録を打ち立てた「スカイラインGT-R」の最終形であるR34や、事実上の後継車であるR35は中古車市場で高い人気を誇ります。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "aboutUsedCarGtR" */ '../components/pages/column/gtr-amg/AboutUsedCarGtR.vue'),
  },
  {
    path: '/column/popularUsedCarGtR',
    name: 'PopularUsedCarGtR',
    meta: { 
      breadcrumb: '【中古車】GT-R R33の新時代！25年ルールがもたらした価値とは？',
      title:  {'ja':'コラム【中古車】GT-R R33の新時代！25年ルールがもたらした価値とは？' ,'en':'column'},
      seoTitle:'【中古車】GT-R R33の新時代！25年ルールがもたらした中古車市場への影響とR33の価値とは | スカイライン・R33の中古車販売「car shop MIX」',
      desc: 'スカイラインGT-RやR33などの希少な中古車を販売する「car shop MIX」が、25年ルールの概要と、そのルールがもたらした中古車市場への影響とスカイラインGT-Rシリーズへの価値について解説します。中古車市場におけるGT-R R33は、日本国内でも往年の名車が注目されています。'
    },
    component: () => import(/* webpackChunkName: "popularUsedCarGtR" */ '../components/pages/column/gtr-amg/PopularUsedCarGtR.vue'),
  },
  {
    path: '/column/nismoUsedCarGtR',
    name: 'NismoUsedCarGtR',
    meta: { 
      breadcrumb: '【中古車】GT-RとGT-Rニスモの違い',
      title:  {'ja':'【中古車】GT-RとGT-Rニスモの違い' ,'en':'column'},
      seoTitle:'【中古車】スカイライン GT-RとGT-Rニスモの違い | スカイラインや希少な中古車ならcar shop MIX',
      desc: '中古車市場で人気のスカイライン GT-Rニスモは、GT-Rのハイエンドモデルです。日産自動車のモータースポーツ事業を担う「ニスモ」が開発を行っており、現行GT-Rとはスペックや価格面での違いがあります。GT-Rニスモをはじめ、スカイラインなどの希少な中古車をお探しの方は、car shop MIXをご利用ください。'
    },
    component: () => import(/* webpackChunkName: "nismoUsedCarGtR" */ '../components/pages/column/gtr-amg/NismoUsedCarGtR.vue'),
  },
  {
    path: '/column/pointUsedCarGtR',
    name: 'PointUsedCarGtR',
    meta: { 
      breadcrumb: '【中古車】GT-Rを選ぶ際のポイントとは？低走行以外にも注目！',
      title:  {'ja':'【中古車】GT-Rを選ぶ際のポイントとは？低走行以外にも注目！' ,'en':'column'},
      seoTitle:'【中古車】GT-Rを選ぶ際のポイントとは？低走行以外にも注目！ | スカイラインGT-Rシリーズの希少車ならcar shop MIX',
      desc: 'グレードや型式、価格相場など、GT-Rの中古車を選ぶポイントをご紹介します。グレードごとの特徴や中古車市場での価格相場を理解し、自分にとって最適なGT-Rを選んでください。スカイラインGT-Rシリーズの中古車探しは、car shop MIXがサポートいたします。'
    },
    component: () => import(/* webpackChunkName: "pointUsedCarGtR" */ '../components/pages/column/gtr-amg/PointUsedCarGtR.vue'),
  },
  {
    path: '/column/differenceUsedCarAmg',
    name: 'DifferenceUsedCarAmg',
    meta: { 
      breadcrumb: '【中古車】AMGとは？GクラスのAMGモデルをご紹介',
      title:  {'ja':'【中古車】AMGとは？GクラスのAMGモデルをご紹介' ,'en':'column'},
      seoTitle:'【中古車】AMGとは？GクラスのAMGモデルをご紹介 | car shop MIX',
      desc: 'メルセデスベンツの中古車市場で人気を集めるGクラスですが、中でも最上級グレードとして位置付けられているAMGモデルがおすすめです。通常のGクラスとGクラスAMGを比較し、それぞれの違いや特徴を把握しておきましょう。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "differenceUsedCarAmg" */ '../components/pages/column/gtr-amg/DifferenceUsedCarAmg.vue'),
  },
  {
    path: '/column/charmUsedCarAmg',
    name: 'CharmUsedCarAmg',
    meta: { 
      breadcrumb: '【中古車・AMG】CクラスとCLAクラスの魅力を解説',
      title:  {'ja':'【中古車・AMG】CクラスとCLAクラスの魅力を解説' ,'en':'column'},
      seoTitle:'【中古車・AMG】CクラスとCLAクラスの魅力を解説 | car shop MIX',
      desc: '高級感溢れる内外装と高いパフォーマンスを発揮するメルセデスベンツの中でも、日本人に合ったコンパクトボディが特徴のCクラスは、中古車市場でも高い人気を誇ります。高性能なAMGモデルもおすすめです。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "charmUsedCarAmg" */ '../components/pages/column/gtr-amg/CharmUsedCarAmg.vue'),
  },
  {
    path: '/column/chooseUsedCarAmg',
    name: 'ChooseUsedCarAmg',
    meta: { 
      breadcrumb: '【中古車・AMG】低走行だけが重要ではない！ベストな走行距離とは',
      title:  {'ja':'【中古車・AMG】低走行だけが重要ではない！ベストな走行距離とは' ,'en':'column'},
      seoTitle:'【中古車・AMG】低走行だけが重要ではない！ベストな走行距離とは | car shop MIX',
      desc: '走行距離に関わらず安全で快適な中古車を選ぶためには、年式と走行距離の正しい関係を知っておくことが重要です。AMGモデルなど中古車購入時の選び方や注意すべきポイントを把握しておきましょう。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "chooseUsedCarAmg" */ '../components/pages/column/gtr-amg/ChooseUsedCarAmg.vue'),
  },
  {
    path: '/column/typeUsedCarAmg',
    name: 'TypeUsedCarAmg',
    meta: { 
      breadcrumb: '【中古車】AMGのラインナップと45S搭載の車種をご紹介',
      title:  {'ja':'【中古車】AMGのラインナップと45S搭載の車種をご紹介' ,'en':'column'},
      seoTitle:'【中古車】AMGのラインナップと45S搭載の車種をご紹介 | car shop MIX',
      desc: 'メルセデスの中古車をお探しの方へ、メルセデスAMGをおすすめします。様々なラインナップがありますので、それぞれの特徴をしっかりと把握し、自分に合ったものを見つけましょう。希少な車種をお探しの方は、car shop MIXにご相談ください。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "typeUsedCarAmg" */ '../components/pages/column/gtr-amg/TypeUsedCarAmg.vue'),
  },
  {
    path: '/column/brandUsedCarPremiumCar',
    name: 'BrandUsedCarPremiumCar',
    meta: { 
      breadcrumb: 'プレミアムカーは中古車もおすすめ！国産車・外車の人気メーカーをご紹介',
      title:  {'ja':'プレミアムカーは中古車もおすすめ！国産車・外車の人気メーカーをご紹介' ,'en':'column'},
      seoTitle:'プレミアムカーは中古車もおすすめ！国産車・外車の人気メーカーをご紹介 | car shop MIX',
      desc: '国産車から外車まで様々なメーカーがありますので、どのメーカーのプレミアムカーが自分には合っているのかわからないという方は多いでしょう。中古車を購入する前に、各メーカーの特徴を把握しておくことが大切です。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "brandUsedCarPremiumCar" */ '../components/pages/column/maker-mt/BrandUsedCarPremiumCar.vue'),
  },
  {
    path: '/column/chooseUsedCarPremiumCar',
    name: 'ChooseUsedCarPremiumCar',
    meta: { 
      breadcrumb: '【プレミアムカー】中古車（MT・AT）の販売店を選ぶときのコツ',
      title:  {'ja':'【プレミアムカー】中古車（MT・AT）の販売店を選ぶときのコツ | car shop MIX' ,'en':'column'},
      seoTitle:'【プレミアムカー】中古車（MT・AT）の販売店を選ぶときのコツ | car shop MIX',
      desc: 'プレミアムカーの中古車を購入する前に、信頼できる販売店を探すのが鉄則です。中古車販売店を選ぶときは、口コミや保証制度の有無、取り扱う車種、スタッフの対応、展示車の状態、値引きに対応しているかなどを確認しましょう。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "chooseUsedCarPremiumCar" */ '../components/pages/column/maker-mt/ChooseUsedCarPremiumCar.vue'),
  },
  {
    path: '/column/reasonUsedCarPremiumCar',
    name: 'ReasonUsedCarPremiumCar',
    meta: { 
      breadcrumb: '【プレミアムカー】中古車が高値となった理由を解説',
      title:  {'ja':'【プレミアムカー】中古車が高値となった理由を解説 | car shop MIX' ,'en':'column'},
      seoTitle:'【プレミアムカー】中古車が高値となった理由を解説 | car shop MIX',
      desc: 'プレミアムカーの中古車の販売価格は一時高騰していましたが、現在はようやく落ち着きをみせています。高値になった理由と価格高騰が落ち着いた要因を知ることで、購入するタイミングを理解できます。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "reasonUsedCarPremiumCar" */ '../components/pages/column/maker-mt/ReasonUsedCarPremiumCar.vue'),
  },
  {
    path: '/column/carefulUsedCarPremiumCar',
    name: 'CarefulUsedCarPremiumCar',
    meta: { 
      breadcrumb: '【プレミアムカー】中古車を購入する際の注意点！日産やホンダは狙い目？',
      title:  {'ja':'【プレミアムカー】中古車を購入する際の注意点！日産やホンダは狙い目？ | car shop MIX' ,'en':'column'},
      seoTitle:'【プレミアムカー】中古車を購入する際の注意点！日産やホンダは狙い目？ | car shop MIX',
      desc: '高級車を中古車として購入する際は、いくつか注意しておきたいポイントがあります。特に、購入後の維持費や運転のしやすさは必ず確認しておくことをおすすめします。プレミアムカーを購入したい方は、car shop MIXをご利用ください。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "carefulUsedCarPremiumCar" */ '../components/pages/column/maker-mt/CarefulUsedCarPremiumCar.vue'),
  },
  {
    path: '/column/meritUsedCarMt',
    name: 'MeritUsedCarMt',
    meta: { 
      breadcrumb: '【中古車・MT・国産車】MT車のメリット・デメリット',
      title:  {'ja':'【中古車・MT・国産車】MT車のメリット・デメリット | car shop MIX' ,'en':'column'},
      seoTitle:'【中古車・MT・国産車】MT車のメリット・デメリット | car shop MIX',
      desc: '運転の状況に合わせてギアチェンジが必要なMT車は、自ら運転する楽しみを味わえるのが醍醐味です。中古車（MT）を購入しようかと迷っている方は、メリット・デメリットを理解して検討することをおすすめします。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "meritUsedCarMt" */ '../components/pages/column/maker-mt/MeritUsedCarMt.vue'),
  },
  {
    path: '/column/pointUsedCarMt',
    name: 'PointUsedCarMt',
    meta: { 
      breadcrumb: '【中古車】MT車はシビックが人気？選ぶ際のポイントや注意点について',
      title:  {'ja':'【中古車】MT車はシビックが人気？選ぶ際のポイントや注意点について | car shop MIX' ,'en':'column'},
      seoTitle:'【中古車】MT車はシビックが人気？選ぶ際のポイントや注意点について | car shop MIX',
      desc: '運転操作を楽しみたい方におすすめのMT車ですが、中古車を選ぶ際は注意が必要です。中古車購入の際に失敗しないためにも、押さえるべきポイントや人気車種のシビックの特徴を知っておきましょう。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "pointUsedCarMt" */ '../components/pages/column/maker-mt/PointUsedCarMt.vue'),
  },
  {
    path: '/column/featureUsedCarMt',
    name: 'FeatureUsedCarMt',
    meta: { 
      breadcrumb: '【中古車・MT】S660・シルビアの特徴や相場について',
      title:  {'ja':'【中古車・MT】S660・シルビアの特徴や相場について | car shop MIX' ,'en':'column'},
      seoTitle:'【中古車・MT】S660・シルビアの特徴や相場について | car shop MIX',
      desc: 'ホンダのS660と日産のシルビアは、MT車好きにはたまらない魅力が数多くある車種です。お気に入りの一台を探すために、人気の2車種の特徴と中古での相場を把握しておきましょう。中古車をお探しでしたら、car shop MIXをご利用ください。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "featureUsedCarMt" */ '../components/pages/column/maker-mt/FeatureUsedCarMt.vue'),
  },
  {
    path: '/column/modelUsedCarMt',
    name: 'ModelUsedCarMt',
    meta: { 
      breadcrumb: '【中古車・MT】NSXの特徴やおすすめのモデルとは？',
      title:  {'ja':'【中古車・MT】NSXの特徴やおすすめのモデルとは？ | car shop MIX' ,'en':'column'},
      seoTitle:'【中古車・MT】NSXの特徴やおすすめのモデルとは？ | car shop MIX',
      desc: 'ホンダ・NSXは、生産が終了した今でも、中古車市場で世界的に人気を集めている車種です。NSXの特徴や人気モデルを理解したうえで、お気に入りのMT車を探すことをおすすめします。NSXなど中古車の購入をご検討中の方は、car shop MIXにご相談ください。 | car shop MIX'
    },
    component: () => import(/* webpackChunkName: "modelUsedCarMt" */ '../components/pages/column/maker-mt/ModelUsedCarMt.vue'),
  },
  {
    path: '/jigyo',
    name: 'JigyoTop',
    meta: { 
      title:  {'ja':'事業内容' ,'en':'Business'},
      seoTitle:'事業内容 | 中古車なら株式会社 MIX YARD | 希少な国産車や限定車、ベンツなどの外車もあります',
      desc: '株式会社 MIX YARDの事業内容ページです。中古の限定車や希少車、旧車の販売、バンニング、車両メンテナンスを行っています。スカイラインGT-Rなどの希少な中古車、ベンツやランボルギーニなどの外車の中古車でしたら、ぜひ株式会社 MIX YARDにお任せください。'},
    component: () => import(/* webpackChunkName: "jigyoTop" */ '../components/pages/JigyoTop.vue'),
  },
  {
    path: '/jigyo/oldCars',
    name: 'OldCars',
    meta: { 
      title: {'ja':'中古車' ,'en':'Old cars' },
      seoTitle:'中古車 | 株式会社 MIX YARDはスカイラインGT-Rや旧車・外車など希少中古車を販売',
      desc: '希少な中古車（スカイラインGT-RやメルセデスAMGなど）をお探しの方は、株式会社 MIX YARDへお問い合わせください。一般市場ではなかなか見つからない中古の限定車や希少車、旧車を中心に取り扱っております。あの頃憧れた国産車・外車、MT車・AT車を問わずプレミアムカーを中心に販売いたします。'},
    component: () => import(/* webpackChunkName: "oldCars" */ '../components/pages/jigyo/OldCars.vue')
  },
  {
    path: '/jigyo/vanning',
    name: 'Vanning',
    meta: { title:  {'ja':'バンニング','en':'Vanning' }},
    component: () => import(/* webpackChunkName: "vanning" */ '../components/pages/jigyo/Vanning.vue')
  },
  {
    path: '/jigyo/maintenance',
    name: 'Maintenance',
    meta: { title:  {'ja':'車両メンテナンス','en':'Maintenance' }},
    component: () => import(/* webpackChunkName: "maintenance" */ '../components/pages/jigyo/Maintenance.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: {'ja':'お問い合わせ' ,'en':'Contact'},
      desc: 'お気軽にお問い合わせください。'
    },
    component: () => import(/* webpackChunkName: "contact" */ '../components/pages/Contact.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // スクロールを上に戻す
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else { return { x: 0, y: 0 }   }
  }
})

export default router;

