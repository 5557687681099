<template>
  <div>
    <footer class="uk-light uk-padding-small" >
      <div class="uk-child-width-1-2 uk-text-left" uk-grid>
        <div class="company-name">MIX YARD</div>
      </div>
      <div class="uk-text-left" uk-grid>
        <div class="uk-width-2-3@m">
          <table class="uk-table ">
              <tbody>
                  <tr >
                    <td class="uk-padding-remove-vertical ">{{profiles[0].detail}}</td>
                    <td class="uk-padding-remove-vertical ">{{profiles[5].detail}}</td>
                  </tr>
                  <tr >
                    <td class="uk-padding-remove-vertical ">{{profiles[2].detail}}</td>
                    <td class="uk-padding-remove-vertical ">{{profiles[7].detail}}</td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div class=" uk-text-right uk-width-1-3@m">
          <a href="https://www.facebook.com/Car-Shop-MIX-608658045986382" target="_blank" ><span class="uk-margin-right" uk-icon="icon:facebook; ratio: 3"></span></a>
          <a href="https://www.instagram.com/carshopmix/" target="_blank" ><span class="uk-margin-right" uk-icon="icon:instagram; ratio: 3"></span></a>
        </div>
      </div>
      <div class=" uk-text-right ">
        © 2014-2022 MIX YARD  
      </div>
    </footer>
  </div>
</template>


<script>
export default {
  data: () => ({
    profiles:[]
  }),
  created() {
    // メインメニュー作成
    this.profiles = this.commonSetting.companyProfile;
  },
}
</script>



<style scoped lang="scss">
footer {
  background-color: var(--main-color);
  position: sticky;/*固定する*/
  position: -webkit-sticky;/*Safari用ベンダープレフィックス*/
  bottom: 0;
  z-index: 80;
  font-weight: 600;
}

.company-name{
  font-size: 1.2rem;
}


</style>
