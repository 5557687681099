
<template>
<div class="mini-menu">
  <ul>
    <li v-for="(info, pageName, i) of pageNames" :key="i" >
      <router-link uk-toggle="target: #offcanvas-reveal" :to="info.path">{{$_chlang(info.title)}}</router-link>
    </li>
  </ul>
</div>  
</template>

<script>
export default {
  props: {
    pageNames:{},
  },
}
</script>

<style scoped lang="scss">
.mini-menu{
  padding :2vw;
  text-align: left;
  box-sizing: border-box;
  ul{    padding :2vw;  }

  a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    color: rgb(212, 212, 212);
    text-decoration: none;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transform: translate(-101%, 0 );
      transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
      content: "";
    }
    &:hover:after {
      transform: translate(0, 0);
    }
  }

  li {
    list-style: none;
    margin: 10px 0;
  }
}
</style>
