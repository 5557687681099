<!-- mixyardのLogoをコネコネする -->
<template>
    <div >
      <router-link to="/">
        <mixyard-logo />
      </router-link>
    </div>
</template>

<script>
import MixyardLogo from "@/assets/img/MixlogoAnimation.svg"

export default {
  components: {
    MixyardLogo,
  },
};
</script>

<style scoped lang="scss">
svg {
    width: 100%;
    height:100%;

    stroke: transparent;
    filter: drop-shadow(1.5px 1.5px 0.8px #FFF) ;
  
  @keyframes icon-parts3 {
     0% {opacity: 1;}
     2% {opacity: 0;}
     6% {opacity: 0;}
     8% {opacity: 1;}
    10% {opacity: 1;}
    12% {opacity: 0;}
    16% {opacity: 0;}
    18% {opacity: 1;}
    20% {opacity: 1;}
    22% {opacity: 0;}
    26% {opacity: 0;}
    28% {opacity: 1;}
    100% {opacity: 1; }
  }
  @keyframes icon-parts2 {
     0% {opacity: 0;}
     2% {opacity: 1;}
     4% {opacity: 0;}
     6% {opacity: 1;}
     8% {opacity: 0;}
    10% {opacity: 0;}
    12% {opacity: 1;}
    14% {opacity: 0;}
    16% {opacity: 1;}
    18% {opacity: 0;}
    20% {opacity: 0;}
    22% {opacity: 1;}
    24% {opacity: 0;}
    26% {opacity: 1;}
    28% {opacity: 0;}
    100% {opacity: 0; }
  }
  @keyframes icon-parts1 {
     0% {opacity: 0;}
     2% {opacity: 0;}
     4% {opacity: 1;}
     6% {opacity: 0;}
    12% {opacity: 0;}
    14% {opacity: 1;}
    16% {opacity: 0;}
    22% {opacity: 0;}
    24% {opacity: 1;}
    26% {opacity: 0;}
    100% {opacity: 0; }
  }

  .logo3 {
      /** アニメーション  name | 所要時間 | 減速⇒高速 | 無限に繰り返す */
      -webkit-animation:  icon-parts3  4s linear infinite;
      animation:  icon-parts3  4s linear infinite;
  }
  .logo1 {
      -webkit-animation:  icon-parts1  4s linear infinite;
      animation:   icon-parts1  4s linear infinite;
  }
  .logo2 {
      -webkit-animation:  icon-parts2  4s linear infinite;
      animation:  icon-parts2  4s linear infinite;
  }

}


</style>
