
<template>
  <div id="app">
    <div>
      <div v-if="show" style="height:100vh;">
        <top-open />
      </div>
      <transition name="fade">
        <div v-if="!(show)">
          <header-menu class="header-menu" />
          <router-view class="page-area" />
          <footer-parts />
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

import HeaderMenu from './components/modules/Header.vue'
import FooterParts from './components/modules/Footer.vue'
import TopOpen from "./components/pages/Top.vue"

export default {
  name: 'App',
  components: {
    HeaderMenu,
    FooterParts,
    TopOpen
  },
  data(){
    return {
      show: true, // TOPページの表示制御
    }
  },
  watch: {
    // 遷移時 or Reload
    '$route': function (to) {
      this.isShow(to.path);
      this.setDocumentTitle(to);
    },
  },
  methods: {
    // topページの表示切替
    isShow(path) {
      this.show = (path === "/");
    },
    // タイトルを設定
    setDocumentTitle(route){
      const seoTitle = route.meta.seoTitle
      let seoLength = seoTitle?.length ?? 0
      if(seoLength > 0)  document.title = seoTitle

      const title = this.$_chlang(route.meta.title)
      const defaultTitle = '国産車・外車を問わず希少な中古車・プレミアムカーを販売 | 株式会社 MIX YARD'
      
      if(seoLength== 0){
        document.title = (title) ? title : defaultTitle;
      }
      
      // ディスクリプションを設定
      let setDesc =  (route.path === "/") ? this.commonSetting.defaultDesc : ''
      if(route.meta.desc){
        setDesc = route.meta.desc;
      }
      document.querySelector("meta[name='description']").setAttribute('content', setDesc)
    }
  },
  // ブラウザの設定が日本語以外は英語表示
  mounted: function() {
    if (navigator.language.split("-")[0] !== 'ja'){
      this.$i18n.locale = 'en';
    }
    this.setDocumentTitle(this.$route);
  }
};
</script>



<style lang="scss">


@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css');
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Murecho:wght@100;200;300;600;900&display=swap');

$_mini-size: 640px;
$_tab-size: 960px;
$_lap-size: 1200px;

:root {
  --main-color: #c49a00;
  --main-bgcolor: rgb(35, 35, 35);
  --font-family-text:  'Murecho', "SF Pro JP", "SF Pro Text", "SF Pro Icons", "BIZ UDPGothic", Meiryo, sans-serif,cursive;
  --font-family-menu:  'Klee One',sans-serif,cursive;
  --neon-color: var(--main-color);
  font-size: 16px;

  .c-btn .c-btn-anchor:nth-child(2) {margin: 20px 0 0 45px;}

  @media screen and (max-width: $_tab-size) {
    .c-btn .c-btn-anchor:nth-child(2) {margin: 20px 0 0 0;}
    font-size: 14px;
  }
  @media screen and (max-width: $_mini-size) {
    .c-btn .c-btn-anchor:nth-child(2) {margin: 20px 0 0 0;}
    font-size: 12px;
    
    .seo-text {
      padding: 1.5em 1em;
    }
  }
}

body {
  font-family: var(--font-family-text);
  font-weight: 400;
  color: #fff !important;
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--font-family-menu) !important;
}

.contents {
  height: 100%;
  min-height: 90vh;
}
.contents-one {
  height:90vh;
  min-height:90vh;
}
.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  min-height: 90vh;
  padding: 4%;
}

.main-card{
  background-color:rgba(2, 2, 2, 0.6);
  padding:2% 0;
  border-radius: 10px ;

  .sub-card{
    width:92%;
    padding:2%;
    margin:2%;
  }
  >*a:hover{
    text-decoration: none;
  }
}

.sub-card-top{
  background-size: cover;
  background-position: center;
  position: relative;
  height: 40vh;
  
  .parent {
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    right:  0;

    p.text {
      position: absolute;
      width: 60%;
      bottom: 0;
      margin: 3%;  
      @media screen and (max-width: $_tab-size) {
        width: 95%;
      }
    }
    .button-area{
      position: absolute;
      right:  0;
      bottom: 0;
      @media screen and (max-width: $_tab-size) {
       bottom: auto;
      }
      span{
        @media screen and (max-width: $_lap-size) {
          font-size: 1.2rem;
        }
      }

      margin: 3%;
      text-align: right;
    }
  }

}


.section-title{
  border: 3px solid rgb(146, 146, 146);
  font-family: var(--font-family-cap);
  padding: 1vh 2vw;
  margin:2vh 20vw 2vh 0 ;
  font-size: 1.7rem;
  text-shadow: 2px 2px 2px #272727;
}
.section-main{
  background-color: rgba(65, 65, 65, 0.6);
  padding: 2vh 3vw 6vh 3vw;
  font-weight: 400 ;
}

.heading-number {
	position: relative;
  color: #ffffff;
  font-weight: 600;
}

.heading-number::before {
	content: attr(data-number);
	display: inline-block;
	margin-right: 20px;
	color: var(--main-color);
	font-size: 2rem;
	border-bottom: 1px solid  var(--main-color);
}
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation{
  left: 0px!important;
}
/* フェードイン・フェードアウト中の設定 */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
/* フェードイン開始時・フェードアウト終了時の設定 */
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.header-menu{
  position: absolute;
  top:0px;
  left:0px;
  z-index: 2;
  width:100%;
}
.page-area{
  height: 100%;
  background-color: rgb(66, 66, 66);
}

/*ボタン 共通*/
.c-btn { text-align: center;margin: 35px 0 0; }
.c-btn-anchor {
  background: #c49a00;border-radius: 10px;box-shadow: 0 0 7px 3px rgba(73,73,73,.69);
  color: #fff !important;display: inline-block;padding: 12px 20px;
  position: relative;text-decoration: none;transition: .3s;max-width: 500px;width: 87%;
  &:hover {
    background: #c5a42c;box-shadow: 0 0 7px 3px rgba(196,154,0,.63);
    color: #fff;text-decoration: none;
  }
}


/*== ボタン共通設定 */
.btn{
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  border: 1px solid rgb(255, 255, 255);/* ボーダーの色と太さ */
  padding: 2vh 2vw;
  text-align: center;
  font-size: 1.4rem;
  outline: none;
  transition: ease .2s;

    span {
      position: relative;
      z-index: 3;/*z-indexの数値をあげて文字を背景よりも手前に表示*/
      color:#fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background:rgba(76, 76, 76, 0.4);/*背景色*/
      width: 100%;
      height: 100%;
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover:before{
      transform-origin:left top;
      transform:scale(1, 1);
    }
}

.catch_btn {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  padding: 1rem 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}
.btn-copy {
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
  margin-bottom: .2em;
  text-align: center;

  &:before {
    margin-right: 1rem;
    content: '＼';
  }
  &:after {
    margin-left: 1rem;
    content: '／';
  }
}


a.btn--yellow {
  color: #000;
  background-color: #ffaf1b;

  &:hover {
    color: #000;
    background: #ffbc40;
  }
}

a.btn--yellow.btn--cubic {
  border-bottom: 5px solid #9e6a08;
  &:hover {
    margin-top: 3px;
    border-bottom: 2px solid #9e6a08;
  }
}


a.btn-c {
  font-size: 1.4rem;
  position: relative;
  padding: 1.5rem 3rem 1.5rem 2rem;
  border-radius: 100vh;
  i.fa {
    margin-right: 1rem;
  }
  &:before {
    font-size: 1.4rem;
    line-height: 1;
    position: absolute;
    top: calc(50% - .8rem);
    right: 1rem;
    margin: 0;
    padding: 0;
    content: '>';
  }
}
</style>
