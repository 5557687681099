<template>

  <div class="top-open">  
    <mini-menu :pageNames="pageNames" />   
    <div class="uk-position-center">
      <mixyard-icon class="mixyard-icon "  />
      <div class="btn-stocklist">
        <a  href="https://www.worldingsysdom.com/stock_info/list/car" target="_blank" >
          {{"国産車・外車の希少な中古車\n在庫はこちら"}}
        </a>
      </div>
      <div class="image-container"  :style="{ width: width + 'px' }">
        <a   href="https://v4.mixyard.jp/wild-freaks/" target="_blank" >
          <img class="responsive-image" src="@/assets/img/wildfreaks.jpg" alt="wildfreaks">
        </a>
      </div>
    </div>
        <div id="video-area">
          <video id="video" poster="@/assets/img/top-movie.gif" webkit-playsinline playsinline muted autoplay loop>
            <!--
                poster：動画ファイルが利用できない環境で代替表示される画像
                webkit-playsinline：iOS 9までのSafari用インライン再生指定
                playsinline：iOS 10以降のSafari用インライン再生指定
                muted：音声をミュートさせる
                autoplay：動画を自動再生させる
                loop：動画をループさせる
                controls：コントロールバーを表示する
            -->
          <source src="@/assets/img/top-movie.mp4" type="video/mp4">
          <source src="@/assets/img/top-movie.webm" type="video/webm">
          <p>動画を再生できる環境ではありません。</p>
          </video>
        </div>
  </div>
  
</template>


<script>
import MiniMenu from "../modules/parts/MiniMenu.vue"
import MixyardIcon from "@/assets/img/Mixlogo2.svg"

export default {
  components: {
    MixyardIcon,
    MiniMenu,
  },
  data: () => ({
    pageNames:{},
    width: 0,
  }),
  created() {
    // メインメニュー作成
    this.pageNames = this.$_menu(this.commonSetting.menuList);
  },
  mounted() {
    this.$nextTick(() => {
      const imageContainer = this.$el.querySelector('.btn-stocklist');
      if (imageContainer) {
        this.width = imageContainer.offsetWidth;
      }
    });
  }
}
</script>



<style scoped lang="scss">
.top-open {
  // background-image: url(~@/assets/img/top-movie.gif);
  // background-position: center;
  // background-size: cover;
  height: 100vh;
  text-align: center;
}

svg.mixyard-icon {
  width:17vh;
  filter: drop-shadow(2px 2px 2px rgba(255, 255, 255)) 
          drop-shadow(-2px -2px 2px rgba(255, 255, 255));
}


@import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stick&display=swap');

.btn-stocklist{
  padding :2vh 0;
  margin: 4vh 0;
  border: 0.25rem solid #ffeca8;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem 3rem 1.5rem;
  
  animation: flicker 3s infinite alternate;  
  

  a {
    font-size : 1.5rem;
    font-weight: 200;
    color: #fff;
    white-space: pre-wrap;
    // font-family: 'Kaisei Opti', serif;
    font-family: 'Stick', sans-serif;
    text-decoration: none;
  }
  ::selection {
    background-color: var(--neon-color);
    color: var(--neon-color);
  }
  ::-moz-selection {   @extend ::selection;     }
  &:focus {  outline: none;      }
  &:hover{   background-color: var(--neon-color);      }

  /* Animate neon flicker */
  @keyframes flicker {
      
      0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
        
          text-shadow:
              -0.2rem -0.2rem .5rem rgba(255, 255, 255, 0.5),
              0.2rem 0.2rem .5rem rgba(255, 255, 255, 0.5),
              0 0 2rem var(--neon-color),
              0 0 4rem var(--neon-color),
              0 0 6rem var(--neon-color),
              0 0 8rem var(--neon-color);
          
          box-shadow:
              0 0 .5rem #fff,
              inset 0 0 .5rem #fff,
              0 0 2rem var(--neon-color),
              inset 0 0 2rem var(--neon-color),
              0 0 4rem var(--neon-color),
              inset 0 0 4rem var(--neon-color);        
      }
      
      20%, 24%, 55% {        
          text-shadow: none;
          box-shadow: none;
      }    
  }
}
.top-label {
  margin : 0 auto 40px;
  width : 100%;
  font-size : 0.8rem;
  text-align : center;
  overflow : hidden;

  p{
    color: rgba(210, 210, 210, 0.581);
    margin:0;
    display : inline-block;
    padding-left: 100%;
    white-space : nowrap;
    line-height : 1em;
    animation : scrollSample01 20s linear infinite;
  }
  @keyframes scrollSample01{
  0% { transform: translateX(0)}
  100% { transform: translateX(-100%)}
  }
}

/*========= 背景動画設定のCSS ===============*/
#video-area{
    position: fixed;
    z-index: -1;
    top: 0;
    right:0;
    left:0;
    bottom:0;
    overflow: hidden;
}

#video {
    /*天地中央配置*/
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    /*縦横幅指定*/
    width: 100%; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    // width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    // height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
    min-height: 100%;
    min-width: 100%;
    background-image:url(~@/assets/img/top-movie.gif);
}
</style>
